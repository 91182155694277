import { useForm } from "react-hook-form";
import { GenericComponent } from "../../../components/GenericComponent";
import { AccionistaBvg } from "../../../models/cpn/AccionistaBvg";
import { useEffect, useState } from "react";
import { AccionistaBvgService } from "../../../service/personaNatural/AccionistaBvgService";
import { InputComponent } from "../../../components/InputComponent";
import { confirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
import { FormPep } from "../FormPep";
import { Generico } from "../../../components/Generico";
import { FileBvg } from "../../../components/FileBvg";
import { useToken } from "../../../components/useToken";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";

export const AccionistaSocioBvg = ({ idp, permss, catalogos, toast, origen }) => {
    const generic = new GenericComponent();
    const { accionesTbl } = Generico();
    const omodel = new AccionistaBvg();
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues });
    let loadLazyTimeout = null;
    const [registros, setRegistros] = useState(null);
    const [dialogAccionista, setDialogAccionista] = useState(false);
    const accionistaSrv = new AccionistaBvgService();
    const [esRuc, setEsRuc] = useState(false);
    const [dialogPep, setDialogPep] = useState(false);
    const [accionista, setAccionista] = useState(null);
    const [urlsPep, setUrlsPep] = useState();
    const [dialogSubirPep, setDialogSubirPep] = useState(false);
    const { getPerss } = useToken();
    const [load, setLoad] = useState(false);
    const [hayPep, setHayPep] = useState({ lab: omodel.optionLabel });
    const { table, setLoading, setTotalRecords, lazyParams, inDrop, inText, inNumber } =
        InputComponent({ registers: registros, omodel, generic, errors, control, permss, id: getValues('id') });

    useEffect(() => {
        loadLazy();
        setUrlsPep({
            urlListado: 'ficha/pep-ficha',
            urlDownload: 'ficha/pep-ficha/descarga',
            urlUpload: 'ficha/pep-ficha/store',
            urlVerificacion: 'ficha/pep-ficha',
            udelete: 'ficha/pep-ficha/eliminar-anexo',
            uactual: 'ficha/pep-ficha/editar',
            uenviar: 'ficha/pep-ficha/enviar',
            uaprobar: 'ficha/pep-ficha/aprobar'
        });
    }, [idp]);

    const loadLazy = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }

        loadLazyTimeout = setTimeout(() => {
            let _lazyParams = lazyParams;
            _lazyParams.idp = idp;
            accionistaSrv.lista({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setRegistros(res.data.registros);
                setLoading(false);
            });
        });
    }

    const openNew = () => {
        setHayPep({ lab: omodel.optionLabel });
        setDialogAccionista(true);
        reset(defaultValues);
    }

    const onSubmit = (data) => {
        data.idp = idp;
        data.ruc = catalogos.ruc;
        data.analogo = catalogos.analogo;
        setLoad(true);
        if (getValues('id') === undefined || getValues('id') == null) {
            accionistaSrv.newAccionista(data).then(res => {
                setDialogAccionista(false);
                setLoad(false);
                loadLazy();
                generic.funcSuccess(res, toast);
            }).catch(error => {
                setLoad(false);
                generic.funcError(error, toast, setError);
            });
        }
        else {
            accionistaSrv.editar(data).then(res => {
                setLoad(false);
                setDialogAccionista(false);
                loadLazy();
                generic.funcSuccess(res, toast);
            }).catch(error => {
                setLoad(false);
                generic.funcError(error, toast, setError);
            });
        }
    }

    const confirmDelete = () => {
        confirmDialog({
            message: '¿Está seguro de eliminar?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => eliminarAccionista()
        });
    }

    const eliminarAccionista = () => {
        accionistaSrv.eliminar(accionista?.id, idp).then(res => {
            loadLazy();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const editAccionista = (data) => {
        accionistaSrv.consultar(data.id, idp).then(res => {
            const lista = {
                tipo_identificacion: catalogos.tipo_accionista,
                nacionalidad: catalogos.nacionalidad
            };
            const _accionista = res.data;
            if (_accionista.tipo_identificacion !== catalogos.ruc.cat_id) {
                lista['es_pep'] = generic.sino
            }

            setEsRuc(_accionista.tipo_identificacion === catalogos.ruc.cat_id
                || _accionista.tipo_identificacion === catalogos.analogo.cat_id
            );
            Object.keys(lista).forEach(function (key) {
                if (_accionista[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _accionista[key]) {
                            _accionista[key] = element;
                        }
                    });
                }
            });
            const _siPep = { lab: omodel.optionLabel };
            if (_accionista.pip) _siPep.ne = true;
            setHayPep(_siPep);
            reset(generic.renewValues(_accionista, omodel.model));
            setDialogAccionista(true);
        });
    }

    const onChangeTipo = (e) => {
        if (!e.value) {
            setEsRuc(false);
        } else {
            setEsRuc(e.value.cat_id === catalogos.ruc.cat_id || e.value.cat_id === catalogos.analogo.cat_id);
        }
    }

    const mostrarPep = (data) => {
        setDialogPep(true);
        setAccionista(data);
    }

    const reiniciarPep = () => {
        setDialogPep(false);
        loadLazy();
    }

    const itemsMenu = () => {
        const items = [];
        if (permss?.actualizar && !origen)
            items.push({ label: 'Editar', icon: 'pi pi-pencil', command: () => { editAccionista(accionista) } });
        if (permss?.crear && !origen && accionista?.es_pep === true && accionista?.estado_pep !== 'FINALIZADO' &&
            (!getPerss() || (getPerss() && (accionista?.estado_pep === 'BORRADOR' || !accionista?.pip))))
            items.push({ label: 'Formulario Pep', icon: 'pi pi-file', command: () => { mostrarPep(accionista) } });
        if (permss?.consultar && accionista?.pip)
            items.push({ label: 'Anexos formulario(PEP)', icon: 'pi pi-reddit', command: () => { setDialogSubirPep(true) } });
        if (permss?.eliminar && !origen && !accionista?.pip)
            items.push({ label: 'Eliminar', icon: 'pi pi-trash', command: () => { confirmDelete() } });

        return [{ label: 'Opciones', items: items }];
    }

    const reiniciar = () => {
        loadLazy();
        setDialogSubirPep(false);
    }

    const headerTemplate = (options) => {
        return (
            <div className={options.className}>
                <div className="flex align-items-center gap-2">
                    {!origen && <Button icon="pi pi-plus" className="p-button-rounded p-button-success mr-2" type="button" onClick={openNew} style={{ display: permss?.crear ? 'block' : 'none' }} />}
                    <span className="font-bold">4.- Nómina de Socios/Accionistas: (DDA a partir del 10% de Participación Directa o Indirecta)</span>
                </div>
            </div>
        );
    }

    return (
        <>
            <Panel headerTemplate={headerTemplate} className="panel-ficha">
                {table({ action01: accionesTbl, reg: setAccionista, items: itemsMenu() })}
            </Panel>

            <Dialog visible={dialogAccionista} header={getValues('id') == null ? "Nuevo Accionista/Socio" : "Editar Accionista/Socio"} modal className="p-fluid modal-ssize"
                onHide={e => setDialogAccionista(false)} style={{ maxWidth: '500px' }}>
                <br />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="formgrid grid">
                        {inDrop('tipo_identificacion', catalogos?.tipo_accionista, { lab: omodel.optionLabel, chn: onChangeTipo }, 6)}
                        {inText('identificacion', {}, 6)}
                        {!esRuc && inText('apellidos', {}, 6)}
                        {esRuc && inText('razon_social', {}, 12)}

                        {!esRuc && inText('nombres', {}, 6)}
                        {inDrop('nacionalidad', catalogos?.nacionalidad, { lab: omodel.optionLabel }, 6)}
                        {inNumber('aportacion', 2, true, {}, 6)}
                        {inNumber('participacion', 2, false, {}, 6)}

                        {inText('direccion', {}, 6)}
                        {esRuc && inText('nombre_representante', {}, 12)}
                        {!esRuc && inDrop('es_pep', generic.sino, hayPep, 6)}
                    </div>
                    {generic.buttonsForms(setDialogAccionista, load)}
                </form>
            </Dialog>

            <Dialog visible={dialogPep} header={"Formulario PEP: " + accionista?.razon_social} modal className="p-fluid modal-ssize" onHide={e => setDialogPep(false)} style={{ maxWidth: '1000px' }}>
                <FormPep tipo="accionistaBVG" idp={accionista?.id} idOrigen={accionista?.pip ?? null} permss={permss} toast={toast} reiniciar={reiniciarPep} setDialogClientepn={setDialogPep} />
            </Dialog>

            <Dialog visible={dialogSubirPep} header={"Formulario PEP: " + accionista?.razon_social + (accionista?.estado_pep === 'BORRADOR' ?
                '(estado borrador)' : accionista?.estado_pep === 'ENVIADO' ? '(Receptado)' : ' (fue finalizado)')}
                modal className="p-fluid modal-ssize" onHide={e => setDialogSubirPep(false)} style={{ width: '800px' }}>
                <FileBvg generic={generic} tipo="accionistaBVG" urls={urlsPep} idp={accionista?.pip} toast={toast} permss={permss} estado={accionista?.estado_pep} reiniciar={reiniciar}
                    setDialogSubir={setDialogSubirPep} />
            </Dialog>
        </>
    )
}
