export class Directorio {
    constructor(directorio) {
        this.directorio = directorio;
        this.model = {
            id: {
                value: null,
                export: false
            },
            institucion: {
                value: '',
                header: this.directorio === true ? 'Institución o empresa donde participa' :
                    'Compañía',
                required: 'Campo requerido',
                maxLength: 'Máximo 500 caracteres',
                minLength: 'Mínimo 3 caracteres',
                max: 500,
                min: 3,
                table: true,
            },
            participacion: {
                value: 0,
                table: this.directorio === false,
                header: 'Participación:',
                required: 'Campo requerido',
                footer: 0,
            },
        }
    }
    optionLabel = 'nombre';
    msgDirectorio = 'Si su respuesta fue afirmativa para Directorios, detalle las instituciones o empresas donde participa:';
    msgAccion = 'Si su respuesta es afirmativa para participacion % accionaria. Detalle las compañías y el porcentaje donde su participación represente el 5% o más en el capital social:';
}
