import { set, useForm } from "react-hook-form";
import { InputComponent } from "../../components/InputComponent";
import { Toolbar } from "primereact/toolbar";
import { BancariaService } from "../../service/BancariaService";
import { Bancaria } from "../../models/Bancaria";
import { useState } from "react";
import { Dialog } from "primereact/dialog";
import { confirmDialog } from "primereact/confirmdialog";
import { GenericComponent } from "../../components/GenericComponent";
import { BancariaBVG } from "../../models/cpn/BancariaBVG";
import { Generico } from "../../components/Generico";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";

export const ReferenciaBancaria = ({ tipo, id, toast, permss, bvg, num = 8 }) => {

    const generic = new GenericComponent();
    const { accionesTbl } = Generico();
    const omodel = bvg !== null && bvg !== undefined ? new BancariaBVG() : new Bancaria();
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues });
    let loadLazyTimeout = null;
    const referenciaService = new BancariaService();
    const [bancarias, setBancarias] = useState(null);
    const [dialogReferencia, setDialogReferencia] = useState(false);
    const [referencia, setReferencia] = useState(null);
    const [catalogo, setCatalogo] = useState(null);
    const [load, setLoad] = useState(false);
    const { table, setLoading, setTotalRecords, lazyParams, inText, inDrop } = InputComponent({ registers: bancarias, omodel, generic, errors, control, permss, id: getValues('id') });

    const editBancaria = () => {
        const iniciar = catalogo == null ? (bvg !== null && bvg !== undefined ? 2 : 1) : 0;
        referenciaService.consultarBancaria(referencia?.id, iniciar).then(res => {
            let _cat = catalogo;
            if (iniciar === 1 || iniciar === 2) {
                setCatalogo(res.data.tipo);
                _cat = res.data.tipo;
            }
            const lista = { tipo_cuenta: _cat };
            let _ref = res.data.referencia;

            Object.keys(lista).forEach(function (key) {
                if (_ref[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _ref[key]) {
                            _ref[key] = element;
                        }
                    });
                }
            });

            reset(_ref);
            setDialogReferencia(true);
        });
    }

    const loadLazyBancaria = (idp) => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }

        let _lazyParams = lazyParams;
        _lazyParams.idp = idp == null ? id : idp;
        _lazyParams.tipo = tipo;
        loadLazyTimeout = setTimeout(() => {
            referenciaService.getLista({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setBancarias(res.data.registros);
                setLoading(false);
            });
        });
    }

    const onSubmit = (data) => {
        data.idp = id;
        data.tipo = tipo;
        setLoad(true);
        if (getValues('id') == null) {
            referenciaService.newBancaria(data).then(res => {
                setLoad(false);
                setDialogReferencia(false);
                loadLazyBancaria();
                generic.funcSuccess(res, toast);
            }).catch(error => {
                setLoad(false);
                generic.funcError(error, toast, setError);
            });
        }
        else {
            referenciaService.editarBancaria(data).then(res => {
                setLoad(false);
                setDialogReferencia(false);
                loadLazyBancaria();
                generic.funcSuccess(res, toast);
            }).catch(error => {
                setLoad(false);
                generic.funcError(error, toast, setError);
            });
        }
    }

    const openNew = () => {
        if (catalogo == null) {
            referenciaService.newBancaria({ iniciar: bvg !== null && bvg !== undefined ? 2 : 1 }).then(res => {
                setReferencia(null);
                setCatalogo(res.data);
                setDialogReferencia(true);
                reset(defaultValues);
            });
        }
        else {
            setReferencia(null);
            setDialogReferencia(true);
            reset(defaultValues);
        }
    }

    const confirmDelete = () => {
        confirmDialog({
            message: '¿Está seguro de eliminar la información?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteBancaria(referencia?.id)
        });
    };

    const deleteBancaria = (id) => {
        referenciaService.deleteBancaria(id).then(res => {
            loadLazyBancaria();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const dialogoBancaria = () => {
        return (
            <Dialog visible={dialogReferencia} header={getValues('id') == null ? "Nueva Referencia" : "Editar Referencia"} modal className="p-fluid modal-ssize"
                onHide={e => setDialogReferencia(false)} style={{ width: '400px' }}>
                <br />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="formgrid grid">
                        {inText('institucion_financiera', {}, 12)}
                        {inDrop('tipo_cuenta', catalogo, { lab: omodel.optionLabel }, 12)}
                        {inText('numero_cuenta', {}, 12)}
                    </div>

                    {generic.buttonsForms(setDialogReferencia, load)}
                </form>
            </Dialog>
        )
    }

    const itemsMenu = () => {
        const items = [];
        if (permss?.actualizar)
            items.push({ label: 'Editar', icon: 'pi pi-pencil', command: () => { editBancaria() } });
        if (permss?.eliminar)
            items.push({ label: 'Eliminar', icon: 'pi pi-trash', command: () => { confirmDelete() } });

        return [{ label: 'Opciones', items: items }];
    }

    const tableBancaria = () => {
        return (
            <>
                {id && <Toolbar className="mb-4" start={generic.leftToolbarTemplate(openNew, '', permss)}></Toolbar>}
                {table({ action01: accionesTbl, reg: setReferencia, items: itemsMenu() })}
            </>
        )
    }

    const headerTemplate = (options) => {
        return (
            <div className={options.className}>
                <div className="flex align-items-center gap-2">
                    <Button icon="pi pi-plus" className="p-button-rounded p-button-success mr-2" type="button" onClick={openNew} title="Registrar información"
                        style={{ display: permss?.crear ? 'block' : 'none' }} />
                    <span className="font-bold">{num}.- Referencia Bancaria:</span>
                </div>
            </div>
        );
    }

    const tableBancariaBVG = () => {
        return (
            <>
                <Panel headerTemplate={headerTemplate} className="panel-ficha">
                    {table({ action01: accionesTbl, reg: setReferencia, items: itemsMenu() })}
                    <span style={{ fontSize: '10px' }}><b>Nota:</b> Adjunte el estado de cuenta digital o cartilla, correspondiente a los movimientos del último mes previo a la DDC.</span>
                </Panel>
            </>
        )
    }

    return {
        tableBancaria,
        dialogoBancaria,
        loadLazyBancaria,
        tableBancariaBVG
    }
}
