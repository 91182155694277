import { FilterMatchMode } from "primereact/api";

export class ClientePn {

    model = {
        id: {
            export: true,
            header: 'Código',
            table: true,
            filter: true,
            filterPlaceholder: 'Buscar por código',
            filterField: 'id',
            sort: true,
            matchMode: FilterMatchMode.EQUALS,
            showFilterMatchModes: false,
            headerStyle: { width: '10%', minWidth: '10rem' }
        },
        fecha: {
            value: new Date(),
            export: true,
            header: 'Fecha (día/mes/año):',
            required: 'Fecha es requerido',
            table: true,
            sort: true,
            headerStyle: { width: '10%', minWidth: '10rem' }
        },
        nombre_completo: {
            export: true,
            header: 'Nombre Completo',
            table: true,
            sort: false,
            filter: true,
            headerStyle: { width: '30%', minWidth: '20rem' },
            filterPlaceholder: 'Buscar por apellidos - nombres',
            filterField: 'nombre_completo',
            matchMode: FilterMatchMode.CONTAINS
        },
        identificacion: {
            export: true,
            header: 'No. doc. Identidad:',
            filterPlaceholder: 'Buscar por número de identificación',
            filterField: 'identificacion',
            matchMode: FilterMatchMode.CONTAINS,
            table: true,
            filter: true,
            sort: true,
            headerStyle: { width: '20%', minWidth: '15rem' }
        },
        estado: {
            value: null,
            export: true,
            header: 'Estado:',
            table: true,
            headerStyle: { width: '10%', minWidth: '10rem' }
        },
    }
    optionLabel = 'nombre';
    titulo = 'Instrucciones';
    mensaje = <><b>Instrucciones para completar el proceso de envío de información:</b><ol style={{ textAlign: 'justify' }}>
        <li>En cuanto se registre la información de las secciones de la 1 a la 7, favor guardar la información.</li>
        <li>Cuando la información se guarde, dar click en reporte y el sistema generará el formulario de su información en versión PDF, el cual se deberá firmar electrónicamente, o imprimir y firmar físicamente. Si el formulario es firmado físicamente, este documento deberá ser entregado al vendedor.</li>
        <li>En el botón anexos se deberá cargar el formulario firmado y los anexos requeridos.</li>
        <li>Cuando el formulario y sus anexos sean cargados al sistema favor dar click en enviar.</li></ol></>;
}
