import { useEffect, useRef, useState } from "react";
import { GenericComponent } from "../../../components/GenericComponent";
import { Colaborador } from "../../../models/fun/Colaborador";
import { useToken } from "../../../components/useToken";
import { FuncionarioService } from "../../../service/ficha/FuncionarioService";
import { Toolbar } from "primereact/toolbar";
import { Card } from "primereact/card";
import { Generico } from "../../../components/Generico";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Panel } from "primereact/panel";
import { Divider } from "primereact/divider";
import { TabPanel, TabView } from "primereact/tabview";
import { DatosGenerales } from "./DatosGenerales";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Conyugue } from "./Conyugue";
import { InformacionLaboral } from "./InformacionLaboral";
import { InfFinanciera } from "./InfFinanciera";
import { Activos } from "./Activos";
import { ActivosCuentas } from "./ActivosCuentas";
import { PasivosCuentas } from "./PasivosCuentas";
import { VinculacionBvg } from "./VinculacionBvg";
import { Dialog } from "primereact/dialog";
import { FileBvg } from "../../../components/FileBvg";
import { InputComponent } from "../../../components/InputComponent";
import { FileComponent } from "../../../components/FileComponent";
import { FormPep } from "../FormPep";

export const Funcionario = () => {
    const generic = new GenericComponent();
    const omodel = new Colaborador();
    const { accionesTbl } = Generico();
    const [registros, setRegistros] = useState(null);
    const [catalogos, setCatalogos] = useState(null);
    const [colaborador, setColaborador] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [permss, setPermss] = useState(null);
    const [siCliente, setSiCliente] = useState(false);
    const [dialogCliente, setDialogCliente] = useState(false);
    const toast = useRef(null);
    const [urls, setUrls] = useState(null);
    const [urlsVin, setUrlsVin] = useState(null);
    const [urlsPep, setUrlsPep] = useState(null);
    const { getPerss, getRol } = useToken();
    const [vinEstado, setVinEstado] = useState(null);
    const [dialogSubir, setDialogSubir] = useState(false);
    const [dialogPep, setDialogPep] = useState(false);
    const [dialogVinculacion, setDialogVinculacion] = useState(false);
    const funSrv = new FuncionarioService();
    const [hayPep, setHayPep] = useState(false);
    const [pepBorrador, setPepBorrador] = useState(null);
    const { dialogInstruccion, setdialogIns } = FileComponent({ generic, toast, permss, id: colaborador?.id });
    const [fpendiente, setFpendiente] = useState(null);
    const [estados, setEstados] = useState(null);
    const filters = [fpendiente, estados];
    const { table, setLoading, setTotalRecords, lazyParams } = InputComponent({ registers: registros, omodel, generic, permss, id: colaborador?.id, filters });

    useEffect(() => {
        setUrlsVin({
            urlListado: 'ficha/vinculacion',
            urlDownload: 'ficha/vinculacion/descarga',
            urlUpload: 'ficha/vinculacion/store',
            udelete: 'ficha/vinculacion/eliminar-anexo',
            uactual: 'ficha/vinculacion/editar',
            uenviar: 'ficha/vinculacion/enviar',
            uaprobar: 'ficha/vinculacion/aprobar',
        });
        setUrls({
            urlListado: 'ficha/funcionario',
            urlDownload: 'ficha/funcionario/descarga',
            urlUpload: 'ficha/funcionario/store',
            udelete: 'ficha/funcionario/eliminar-anexo',
            uactual: 'ficha/funcionario/editar',
            uenviar: 'ficha/funcionario/enviar',
            uaprobar: 'ficha/funcionario/aprobar',
            urlGenerar: 'ficha/funcionario/generar-consulta',
        });
        setUrlsPep({
            urlListado: 'ficha/pep-ficha',
            urlDownload: 'ficha/pep-ficha/descarga',
            urlUpload: 'ficha/pep-ficha/store',
            urlVerificacion: 'ficha/pep-ficha',
            udelete: 'ficha/pep-ficha/eliminar-anexo',
            uactual: 'ficha/pep-ficha/editar',
            uenviar: 'ficha/pep-ficha/enviar',
            uaprobar: 'ficha/pep-ficha/aprobar',
            urlGenerar: 'ficha/pep-ficha/generar-consulta',
        });
        setSiCliente(getPerss() !== undefined);
        if (getPerss() === undefined) {
            loadLazyData();
        }
        else {
            setPermss(getPerss());
            editFicha();
        }
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);

        let _lazyParams = lazyParams;
        delete _lazyParams.rtipo;
        if (permss == null) {
            _lazyParams.rtipo = true;
        }
        if (getRol()) {
            _lazyParams.rol = getRol();
        }
        funSrv.listado({ lazyEvent: JSON.stringify(_lazyParams) }, 'funcionario').then(res => {
            setTotalRecords(res.data.total);
            setRegistros(res.data.registros);
            setLoading(false);
            if (res.data.perss !== undefined) {
                setPermss(res.data.perss);
            }
            if (res.data.cats) {
                setCatalogos(res.data.cats);
                setFpendiente(res.data.cats.pendiente);
                setEstados(res.data.cats.estados);
            }
        });
    }

    const openNew = () => {
        setDialogCliente(true);
        setColaborador(null);
        setdialogIns(true);
    }

    const confirmDelete = () => {
        confirmDialog({
            message: '¿Está seguro de eliminar el formulario?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteCliente()
        });
    }

    const deleteCliente = () => {
        funSrv.eliminar(colaborador?.id, 'funcionario', colaborador?.id).then(res => {
            if (!siCliente) {
                loadLazyData();
            }
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const nuevoCliente = (nuevo) => {
        const _colab = colaborador ?? {};
        if (nuevo) {
            if (nuevo?.id !== undefined) _colab.id = nuevo.id;
            if (nuevo?.nombre_completo !== undefined) _colab.nombre_completo = nuevo.nombre_completo;
            if (nuevo?.estado_vinculado !== undefined) {
                setVinEstado(nuevo?.estado_vinculado);
                _colab.estado_vinculado = nuevo.estado_vinculado;
            }
            if (nuevo?.casado !== undefined) _colab.casado = nuevo.casado;
            if (nuevo?.hay_vinculo !== undefined) {
                _colab.hay_vinculo = nuevo.hay_vinculo;
            }
            if (nuevo?.pepBorrador !== undefined) setPepBorrador(nuevo.pepBorrador);
            if (nuevo?.pep !== undefined) {
                _colab.pep = nuevo.pep;
                setHayPep(nuevo.pep);
            }
        }
        setColaborador(_colab);
    }

    const editFicha = () => {
        if (catalogos === null) {
            funSrv.nuevo({ iniciar: 1 }, 'funcionario').then(res => {
                setCatalogos(res.data.cats);
                setDialogCliente(true);
                setColaborador(null);
                setActiveIndex(0);
                setdialogIns(true);
                nuevoCliente({ id: res.data.codigo, pep: res.data.pep, nemonico: res.data.funestado });
            });
        }
        else {
            setDialogCliente(true);
            setActiveIndex(0);
            setdialogIns(true);
            if (colaborador && colaborador?.pep !== undefined) {
                setHayPep(colaborador.pep);
            }
            if (colaborador && colaborador?.estado_pep === 'ENVIADO') {
                setPepBorrador(colaborador?.estado_pep);
            }
        }
    }

    const nextPrev = (btn = true) => {
        const btnNext = btn ? <Button label="Siguiente" type="submit" icon="pi pi-chevron-right" /> :
            <Button label="Siguiente" type="button" icon="pi pi-chevron-right" onClick={e => { handleNext(); }} />;
        return (
            <div className="formgrid grid">
                <div className="field col">
                    <Button label="Anterior" icon="pi pi-chevron-left" type="button" onClick={e => handlePrev()} />&nbsp;
                    {btnNext}
                </div>
            </div>
        )
    }

    const handleNext = () => {
        if (activeIndex < 7) {
            setActiveIndex(activeIndex + 1);
        }
    };

    const handlePrev = () => {
        if (activeIndex > 0) {
            setActiveIndex(activeIndex - 1);
        }
    };

    const itemsMenu = () => {
        const items = [];
        if (permss?.actualizar && (!colaborador?.nemonico || colaborador?.nemonico !== 'FINALIZADO'))
            items.push({ label: 'Editar', icon: 'pi pi-pencil', command: () => { editFicha() } });
        if (permss?.consultar) items.push({ label: 'Anexos formulario', icon: 'pi pi-clone', command: () => { setDialogSubir(true) } });
        if (permss?.consultar && colaborador?.pep === true) items.push({ label: 'Anexos formulario(PEP)', icon: 'pi pi-reddit', command: () => { setDialogPep(true) } });
        if (permss?.consultar && colaborador?.vid) items.push({ label: 'Anexos formulario - Vinculación', icon: 'pi pi-discord', command: () => { setDialogVinculacion(true) } });
        if (permss?.eliminar && (!colaborador?.nemonico || colaborador.nemonico !== 'FINALIZADO'))
            items.push({ label: 'Eliminar', icon: 'pi pi-trash', command: () => { confirmDelete() } });

        return [{ label: 'Opciones', items: items }];
    }

    const regresar = () => {
        setActiveIndex(0);
        setDialogCliente(false);
        if (!siCliente) {
            loadLazyData();
        }
    }

    const reiniciar = () => {
        if (!siCliente) {
            loadLazyData();
        }
        setDialogCliente(false);
        setDialogPep(false);
        setDialogVinculacion(false);
        setDialogSubir(false);
    }

    const reiniciarVinculacion = (est) => {
        if (!siCliente) {
            loadLazyData();
        }
        setActiveIndex(6);
        nuevoCliente({ estado_vinculado: est });
    }

    const reiniciarPep = (ban) => {
        setActiveIndex(4);
        if (ban && ban === 'ENVIADO') {
            setPepBorrador('ENVIADO');
        }
    }

    return (
        <>
            <ConfirmDialog />
            <Toast ref={toast} />
            {!dialogCliente && !siCliente && <Toolbar className="mb-4" start={generic.leftToolbarTemplate(openNew, 'Nuevo', permss)}
                end={generic.rightToolbarTemplate(registros, omodel, 'Reporte_persona_juridica', 'LISTADO PERSONA JURIDICA')}></Toolbar>}
            {!dialogCliente && !siCliente && <Card style={{ maxWidth: '100%', margin: 'auto' }}>
                {table({ action01: accionesTbl, reg: setColaborador, items: itemsMenu() })}
            </Card>}

            {dialogCliente &&
                <Panel header='FORMULARIO POLÍTICA "CONOZCA A SU EMPLEADO" Declaración de Origen y Destino Lícito de Recursos. "FUNCIONARIOS Y COLABORADORES"'
                    style={{ maxWidth: '100%', margin: 'auto' }}>
                    {omodel.titleBVG}
                    <Divider />
                    <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                        <TabPanel header="Sección 1" disabled={colaborador == null}>
                            <DatosGenerales generic={generic} nextPrev={nextPrev} handleNext={handleNext} handlePrev={handlePrev} permss={permss} cliente={colaborador}
                                catalogos={catalogos} toast={toast} seccion="seccion2" nuevoCliente={nuevoCliente} id={colaborador?.id} next={handleNext} />
                        </TabPanel>

                        <TabPanel header="Sección 2" disabled={colaborador == null || !colaborador?.id}>
                            <Conyugue generic={generic} nextPrev={nextPrev} handleNext={handleNext} handlePrev={handlePrev} permss={permss} cliente={colaborador} catalogos={catalogos} toast={toast}
                                seccion="seccion2" nuevoCliente={nuevoCliente} id={colaborador?.id} />
                        </TabPanel>

                        <TabPanel header="Sección 3" disabled={colaborador == null || !colaborador?.id}>
                            <InformacionLaboral generic={generic} nextPrev={nextPrev} handleNext={handleNext} handlePrev={handlePrev} permss={permss} cliente={colaborador} catalogos={catalogos} toast={toast}
                                seccion="seccion3" nuevoCliente={nuevoCliente} id={colaborador?.id} urlsPep={urlsPep} />
                        </TabPanel>

                        <TabPanel header="Sección 4" disabled={colaborador == null || !colaborador?.id}>
                            <InfFinanciera generic={generic} nextPrev={nextPrev} next={handleNext} handlePrev={handlePrev} permss={permss} cliente={colaborador} catalogos={catalogos} toast={toast}
                                seccion="seccion4" nuevoCliente={nuevoCliente} id={colaborador?.id} />
                        </TabPanel>

                        <TabPanel header="Sección 5" disabled={colaborador == null || !colaborador?.id}>
                            <Activos generic={generic} nextPrev={nextPrev} next={handleNext} handlePrev={handlePrev} permss={permss} cliente={colaborador} catalogos={catalogos} toast={toast}
                                seccion="seccion5" nuevoCliente={nuevoCliente} id={colaborador?.id} />
                        </TabPanel>

                        <TabPanel header="Sección 6" disabled={colaborador == null || !colaborador?.id}>
                            <ActivosCuentas generic={generic} nextPrev={nextPrev} next={handleNext} handlePrev={handlePrev} permss={permss} cliente={colaborador} catalogos={catalogos} toast={toast}
                                seccion="seccion6" nuevoCliente={nuevoCliente} id={colaborador?.id} />
                        </TabPanel>

                        <TabPanel header="Sección 7" disabled={colaborador == null || !colaborador?.id}>
                            <PasivosCuentas generic={generic} nextPrev={nextPrev} next={handleNext} handlePrev={handlePrev} permss={permss} cliente={colaborador} catalogos={catalogos} toast={toast}
                                seccion="seccion7" nuevoCliente={nuevoCliente} id={colaborador?.id} setDialogCliente={setDialogCliente} urls={urls} estado={colaborador?.nemonico}
                                cancelar={getPerss() === undefined} reiniciar={reiniciar} tipo='colaboradorBvg' />
                        </TabPanel>

                        {hayPep && <TabPanel header={pepBorrador != null ? "Formulario PEP Receptado" : "Sección PEP"} disabled={colaborador == null || !colaborador?.id || pepBorrador != null}>
                            <FormPep tipo="colaboradorBvg" idp={colaborador?.id} idOrigen={null} permss={permss} toast={toast} urls={urls} reiniciar={reiniciarPep} setDialogClientepn={setDialogCliente} />
                        </TabPanel>}

                        <TabPanel header={(vinEstado === 'ENVIADO' || vinEstado === 'FINALIZADO') ? "Formulario de vinculación receptado" : "Vinculación con terceros"}
                            disabled={colaborador == null || !colaborador?.id || ((vinEstado === 'ENVIADO' || vinEstado === 'FINALIZADO') && siCliente)}>
                            <VinculacionBvg generic={generic} nextPrev={nextPrev} next={handleNext} handlePrev={handlePrev} permss={permss} cliente={colaborador} catalogos={catalogos} toast={toast}
                                seccion="seccion8" nuevoCliente={nuevoCliente} id={colaborador?.vid} idp={colaborador?.id} tipo="vinculadoBvg" urls={urlsVin} reiniciar={reiniciarVinculacion} />
                        </TabPanel>

                    </TabView>
                </Panel>
            }
            {dialogInstruccion('Instrucciones', omodel.mensajeBVG)}
            {dialogCliente && !siCliente && <><br /><Button label="Regresar" icon="pi pi-replay" onClick={regresar} /></>}

            <Dialog visible={dialogSubir} header={"Gestión de anexos: " + colaborador?.nombre_completo} modal className="p-fluid modal-ssize" onHide={e => setDialogSubir(false)} style={{ width: '800px' }}>
                <FileBvg generic={generic} tipo="juridicaBvg" urls={urls} idp={colaborador?.id} toast={toast} permss={permss} estado={colaborador?.nemonico} reiniciar={reiniciar}
                    setDialogSubir={setDialogSubir} />
            </Dialog>

            <Dialog visible={dialogVinculacion} header="Gestión de anexos" modal className="p-fluid modal-ssize" onHide={e => setDialogVinculacion(false)} style={{ width: '800px' }}>
                <FileBvg generic={generic} tipo="vinculadoBvg" urls={urlsVin} idp={colaborador?.vid} toast={toast} permss={permss} estado={colaborador?.estado_vinculado}
                    reiniciar={reiniciar} setDialogSubir={setDialogVinculacion} />
            </Dialog>

            <Dialog visible={dialogPep} header={"Gestión de anexos (PEP): " + colaborador?.nombre_completo} modal className="p-fluid modal-ssize" onHide={e => setDialogPep(false)} style={{ width: '800px' }}>
                <FileBvg generic={generic} tipo="accionistaPnBvg" urls={urlsPep} idp={colaborador?.iep} toast={toast} permss={permss} estado={colaborador?.estado_pep} reiniciar={reiniciar}
                    setDialogSubir={setDialogPep} />
            </Dialog>
        </>
    )
}
