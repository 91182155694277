import { useEffect, useRef, useState } from "react";
import { GenericComponent } from "../../../components/GenericComponent";
import { Generico } from "../../../components/Generico";
import { Vinculación } from "../../../models/fun/Vinculacion";
import { FuncionarioService } from "../../../service/ficha/FuncionarioService";
import { InputComponent } from "../../../components/InputComponent";
import { useToken } from "../../../components/useToken";
import { FileComponent } from "../../../components/FileComponent";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Card } from "primereact/card";
import { Toolbar } from "primereact/toolbar";
import { VinculacionBvg } from "./VinculacionBvg";
import { Dialog } from "primereact/dialog";
import { FileBvg } from "../../../components/FileBvg";

export const VinculacionTercero = () => {
    const generic = new GenericComponent();
    const { accionesTbl } = Generico();
    const omodel = new Vinculación();
    const [registros, setRegistros] = useState(null);
    const funSrv = new FuncionarioService();
    const toast = useRef(null);
    const [dialogVinculo, setDialogVinculo] = useState(false);
    const [vinculo, setVinculo] = useState(null);
    const [urls, setUrls] = useState();
    const [dialogSubir, setDialogSubir] = useState(false);
    const [permss, setPermss] = useState(null);
    const [siCliente, setSiCliente] = useState(false);
    const [catalogos, setCatalogos] = useState(null);
    const [fpendiente, setFpendiente] = useState(null);
    const [estados, setEstados] = useState(null);
    const filters = [fpendiente, estados];
    const { table, setLoading, setTotalRecords, lazyParams } = InputComponent({ registers: registros, omodel, generic, permss, id: vinculo?.id, filters });
    const { getRol, getPerss } = useToken();
    const { dialogInstruccion, setdialogIns } = FileComponent({ generic, tipo: 'clientePn', toast, permss, id: vinculo?.id });

    useEffect(() => {
        setUrls({
            urlListado: 'ficha/vinculacion',
            urlDownload: 'ficha/vinculacion/descarga',
            urlUpload: 'ficha/vinculacion/store',
            udelete: 'ficha/vinculacion/eliminar-anexo',
            uactual: 'ficha/vinculacion/editar',
            uenviar: 'ficha/vinculacion/enviar',
            uaprobar: 'ficha/vinculacion/aprobar',
        });
        setSiCliente(getPerss() !== undefined);
        if (getPerss() === undefined) {
            loadLazyData();
        } else {
            validacion();
        }
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);

        let _lazyParams = lazyParams;
        delete _lazyParams.rtipo;
        if (permss == null) {
            _lazyParams.rtipo = true;
        }
        if (getRol()) {
            _lazyParams.rol = getRol();
        }
        funSrv.listado({ lazyEvent: JSON.stringify(_lazyParams) }, 'vinculacion').then(res => {
            setTotalRecords(res.data.total);
            setRegistros(res.data.registros);
            setLoading(false);
            if (res.data.perss !== undefined) {
                setPermss(res.data.perss);
                setCatalogos(res.data.cats);
                setFpendiente(res.data.cats.pendiente);
                setEstados(res.data.cats.estados);
            }
        });
    }

    const validacion = () => {
        funSrv.nuevo({ iniciar: 1, tipo: 'todos' }, 'vinculacion').then(res => {
            setPermss(res.data.perss);
            if (res.data.perss !== undefined) {
                setVinculo({ id: res.data.codigo });
            }
            setCatalogos(res.data.catalogos);
            setDialogVinculo(true);
        });
    }

    const deleteItem = () => {
        confirmDialog({
            message: '¿Esta seguro de eliminar la matriz?',
            header: 'Confirmar',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Si',
            rejectLabel: 'No',
            accept: () => {
                funSrv.eliminar(vinculo?.id, 'vinculacion', 0).then(res => {
                    generic.funcSuccess(res, toast);
                    loadLazyData();
                }).catch(error => {
                    generic.funcDeleteError(error, toast);
                });
            }
        });
    }

    const btnRegresar = () => {
        return (
            <>
                {!siCliente && dialogVinculo && <Button label="Regresar" icon="pi pi-replay" onClick={() => {setDialogVinculo(false); loadLazyData();}} />}
            </>
        )
    }

    const editFicha = () => {
        setDialogVinculo(true);
    }

    const archivos = () => {
        setDialogSubir(true);
    }

    const openNew = () => {
        setVinculo(null);
        setDialogVinculo(true);
        setdialogIns(true);
    }

    const verificacion = (data) => {
        loadLazyData();
    }

    const reiniciar = () => {
        loadLazyData();
        setDialogVinculo(false);
        setDialogSubir(false);
    }

    const itemsMenu = () => {
        const items = [];
        if (permss?.actualizar && (!vinculo?.nemonico || vinculo.nemonico !== 'FINALIZADO'))
            items.push({ label: 'Editar', icon: 'pi pi-pencil', command: () => { editFicha() } });
        if (permss?.consultar) items.push({ label: 'Anexos formulario', icon: 'pi pi-clone', command: () => { archivos() } });
        if (permss?.eliminar && (!vinculo?.nemonico || vinculo.nemonico !== 'FINALIZADO'))
            items.push({ label: 'Eliminar', icon: 'pi pi-trash', command: () => { deleteItem() } });

        return [{ label: 'Opciones', items: items }];
    }

    return (
        <>
            <Toast ref={toast} />
            <ConfirmDialog />
            {!dialogVinculo && !siCliente && <Toolbar className="mb-4" start={generic.leftToolbarTemplate(openNew, 'Nuevo', permss)}
                end={generic.rightToolbarTemplate(registros, omodel, 'Reporte_pep', 'LISTADO PEP')}></Toolbar>}
            {!dialogVinculo && !siCliente && <Card style={{ maxWidth: '100%', margin: 'auto' }}>
                {omodel.msgEnviado}
                {table({ action01: accionesTbl, reg: setVinculo, items: itemsMenu() })}
            </Card>}
            {dialogVinculo && <VinculacionBvg tipo={vinculo?.tipo} id={vinculo?.id} permss={permss} toast={toast} reiniciar={reiniciar} catalogos={catalogos} urls={urls}
                btnRegresar={btnRegresar} />}

            <Dialog visible={dialogSubir} header="Gestión de anexos" modal className="p-fluid modal-ssize" onHide={e => setDialogSubir(false)} style={{ width: '800px' }}>
                <FileBvg generic={generic} tipo={vinculo?.tipo} urls={urls} idp={vinculo?.id} toast={toast} permss={permss} setDialogSubir={setDialogSubir} reiniciar={reiniciar}
                    loadLazyData={loadLazyData} verificacion={verificacion} estado={vinculo?.nemonico} />
            </Dialog>

            {dialogInstruccion('Instrucciones', omodel.mensajeBVG)}
        </>
    )
}
