import { useEffect, useRef, useState } from "react";
import { GenericComponent } from "../../components/GenericComponent";
import { PepForm } from "../../models/ficha/PepForm";
import { PepFichaService } from "../../service/ficha/PepFichaService";
import { InputComponent } from "../../components/InputComponent";
import { useToken } from "../../components/useToken";
import { Toolbar } from "primereact/toolbar";
import { Card } from "primereact/card";
import { FormPep } from "./FormPep";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
import { FileBvg } from "../../components/FileBvg";
import { Button } from "primereact/button";
import { Generico } from "../../components/Generico";
import { FileComponent } from "../../components/FileComponent";

export const PepFicha = () => {
    const generic = new GenericComponent();
    const { accionesTbl } = Generico();
    const omodel = new PepForm();
    const [pep, setPep] = useState(null);
    const [registros, setRegistros] = useState(null);
    const pepSrv = new PepFichaService();
    const toast = useRef(null);
    const [dialogPep, setDialogPep] = useState(false);
    const [urls, setUrls] = useState();
    const [dialogSubir, setDialogSubir] = useState(false);
    const [permss, setPermss] = useState(null);
    const [siCliente, setSiCliente] = useState(false);
    const [fpendiente, setFpendiente] = useState(null);
    const [estados, setEstados] = useState(null);
    const filters = [fpendiente, estados];
    const { table, setLoading, setTotalRecords, lazyParams } = InputComponent({ registers: registros, omodel, generic, permss, id: pep?.id, filters });
    const { getRol, getPerss } = useToken();
    const { dialogInstruccion, setdialogIns } = FileComponent({ generic, tipo: 'clientePn', toast, permss, id: pep?.id });

    useEffect(() => {
        setUrls({
            urlListado: 'ficha/pep-ficha',
            urlDownload: 'ficha/pep-ficha/descarga',
            urlUpload: 'ficha/pep-ficha/store',
            urlVerificacion: 'ficha/pep-ficha',
            udelete: 'ficha/pep-ficha/eliminar-anexo',
            uactual: 'ficha/pep-ficha/editar',
            uenviar: 'ficha/pep-ficha/enviar',
            uaprobar: 'ficha/pep-ficha/aprobar',
            urlGenerar: 'ficha/pep-ficha/generar-consulta',
        });
        setSiCliente(getPerss() !== undefined);
        if (getPerss() === undefined) {
            loadLazyData();
        } else {
            validacion();
        }
    }, [lazyParams]);

    const validacion = () => {
        pepSrv.newPep({ iniciar: 1, tipo: 'todos' }, 'pep-ficha').then(res => {
            setPermss(res.data.perss);
            if (res.data.perss !== undefined) {
                setPep({ id: res.data.codigo });
            }
            setDialogPep(true);
        });
    }

    const reiniciar = () => {
        loadLazyData();
        setDialogPep(false);
        setDialogSubir(false);
    }

    const loadLazyData = () => {
        setLoading(true);

        let _lazyParams = lazyParams;
        delete _lazyParams.rtipo;
        if (permss == null) {
            _lazyParams.rtipo = true;
        }
        if (getRol()) {
            _lazyParams.rol = getRol();
        }
        pepSrv.getPep({ lazyEvent: JSON.stringify(_lazyParams) }, 'pep-ficha').then(res => {
            setTotalRecords(res.data.total);
            setRegistros(res.data.registros);
            setLoading(false);
            if (res.data.perss !== undefined) {
                setPermss(res.data.perss);
                setFpendiente(res.data.cats.pendiente);
                setEstados(res.data.cats.estados);
            }
        });
    }

    const archivos = () => {
        setDialogSubir(true);
    }

    const openNew = () => {
        setPep(null);
        setDialogPep(true);
        setdialogIns(true);
    }

    const editFicha = () => {
        setdialogIns(true);
        setDialogPep(true);
    }

    const deleteItem = () => {
        confirmDialog({
            message: '¿Esta seguro de eliminar la matriz?',
            header: 'Confirmar',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Si',
            rejectLabel: 'No',
            accept: () => {
                pepSrv.eliminarPep(pep?.id, 'pep-ficha', 'todos').then(res => {
                    generic.funcSuccess(res, toast);
                    loadLazyData();
                }).catch(error => {
                    generic.funcDeleteError(error, toast);
                });
            }
        });
    }

    const verificacion = (data) => {
        loadLazyData();
    }

    const btnRegresar = () => {
        return (
            <>
                {!siCliente && dialogPep && <Button label="Regresar" icon="pi pi-replay" onClick={() => setDialogPep(false)} />}
            </>
        )
    }

    const itemsMenu = () => {
        const items = [];
        if (permss?.actualizar && (!pep?.nemonico || pep.nemonico !== 'FINALIZADO'))
            items.push({ label: 'Editar', icon: 'pi pi-pencil', command: () => { editFicha() } });
        if (permss?.consultar) items.push({ label: 'Anexos formulario', icon: 'pi pi-clone', command: () => { archivos() } });
        if (permss?.eliminar && (!pep?.nemonico || pep.nemonico !== 'FINALIZADO'))
            items.push({ label: 'Eliminar', icon: 'pi pi-trash', command: () => { deleteItem() } });

        return [{ label: 'Opciones', items: items }];
    }

    return (
        <>
            <Toast ref={toast} />
            <ConfirmDialog />
            {!dialogPep && !siCliente && <Toolbar className="mb-4" start={generic.leftToolbarTemplate(openNew, 'Nuevo', permss)}
                end={generic.rightToolbarTemplate(registros, omodel, 'Reporte_pep', 'LISTADO PEP')}></Toolbar>}
            {!dialogPep && !siCliente && <Card style={{ maxWidth: '100%', margin: 'auto' }}>
                {omodel.msgEnviado}
                {table({ action01: accionesTbl, reg: setPep, items: itemsMenu() })}
            </Card>}
            {dialogPep && <FormPep tipo="todos" idp={null} idOrigen={pep?.id ?? 0} permss={permss} toast={toast} reiniciar={reiniciar} btnRegresar={btnRegresar} />}

            <Dialog visible={dialogSubir} header="Gestión de anexos" modal className="p-fluid modal-ssize" onHide={e => setDialogSubir(false)} style={{ width: '800px' }}>
                <FileBvg generic={generic} tipo="todos" urls={urls} idp={pep?.id} toast={toast} permss={permss} setDialogSubir={setDialogSubir} reiniciar={reiniciar}
                    loadLazyData={loadLazyData} verificacion={verificacion} estado={pep?.nemonico} />
            </Dialog>

            {dialogInstruccion('Instrucciones', omodel.mensajeBVG)}
        </>
    )
}
