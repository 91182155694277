import { FilterMatchMode } from "primereact/api";

export class Vinculación {
    model = {
        id: {
            value: null,
            export: true,
            table: true,
            filter: true,
            sort: true,
            matchMode: FilterMatchMode.EQUALS,
            header: 'Código',
        },
        nombre_completo: {
            export: true,
            header: 'Nombre Completo',
            table: true,
            sort: false,
            filter: true,
            headerStyle: { width: '30%', minWidth: '20rem' },
            filterPlaceholder: 'Buscar por apellidos - nombres',
            filterField: 'nombre_completo',
            matchMode: FilterMatchMode.CONTAINS
        },
        identificacion: {
            export: true,
            header: 'No. doc. Identidad:',
            filterPlaceholder: 'Buscar por número de identificación',
            filterField: 'identificacion',
            matchMode: FilterMatchMode.CONTAINS,
            table: true,
            filter: true,
            sort: true,
            headerStyle: { width: '20%', minWidth: '15rem' }
        },
        fecha: {
            value: new Date(),
            export: true,
            header: 'Fecha (día/mes/año):',
            required: 'Fecha es requerido',
            table: true,
            sort: true,
            headerStyle: { width: '10%', minWidth: '10rem' }
        },
        estado: {
            value: '',
            export: true,
            header: 'Estado:',
            filter: true,
            filterSelect: 1,
            table: true,
            lab: 'nombre',
            matchMode: FilterMatchMode.EQUALS,
            headerStyle: { width: '10%', minWidth: '10rem' }
        },
        pendiente: {
            value: '',
            export: true,
            header: 'Anexos Pendientes de áreas: requirentes u Oficial de cumplimiento:',
            table: true,
            filter: true,
            filterSelect: 0,
            lab: 'nombre',
            matchMode: 'isNull',
            taq: 'same',
            severity: 'warning',
            headerStyle: { width: '10%', minWidth: '10rem' }
        },
        idp: {
            value: '',
            export: true,
            table: true,
            header: 'Formulario origen'
        },
    }
    mensajeBVG = <><b>Instrucciones para completar el proceso de envío de información:</b><ol style={{ textAlign: 'justify' }}>
        <li>Mientras registre sus datos en el sistema, los mismos se guardarán cada vez que oprima el botón “Siguiente”.</li>
        <li>En cuanto registre la información de las secciones de la 1 a la 3, por favor debe guardarla.</li>
        <li>Cuando la información se guarde, dar click en “Reporte” y el sistema generará el formulario principal en versión PDF, el cual deberá firmar electrónicamente, o imprimir y firmar físicamente.</li>
        <li>En el botón “Anexos” deberá cargar el formulario firmado y los documentos requeridos, por lo que necesitará lo siguiente:
            <ul>
                <li>Cédula de Identidad NUI, Pasaporte, Visa o Documento de identidad del Declarante.</li>
                <li>Cédula de Identidad NUI, Pasaporte, Visa o Documento de identidad del PEP Vinculado.</li>
                <li>Recibo de cualquiera de los servicios básicos, de la dirección declarada del Declarante.</li>
                <li>Cualquier otro documento solicitado de respaldo.</li>
            </ul>
        </li>
        <li>Cuando el formulario y sus anexos sean cargados al sistema favor dar click en “Enviar”.</li></ol></>;
}
