import { FilterMatchMode } from "primereact/api";

export class Accionista {

    model = {
        id: {
            value: null,
            export: true,
            header: 'Código',
            table: true,
            filter: true,
            filterPlaceholder: 'Buscar por código',
            filterField: 'id',
            sort: true,
            matchMode: FilterMatchMode.EQUALS,
            showFilterMatchModes: false,
            headerStyle: {
                width: '10%',
                minWidth: '10rem'
            }
        },
        fecha: {
            value: null,
            export: true,
            header: 'Fecha:',
            table: true,
        },
        nombre_completo: {
            value: '',
            export: true,
            header: 'Apellidos y Nombres:',
            table: true,
            filter: true,
            sort: true,
            filterPlaceholder: 'Buscar por nombres',
            filterField: 'nombre_completo',
            matchMode: FilterMatchMode.CONTAINS,
            showFilterMatchModes: false,
            headerStyle: { width: '30%', minWidth: '20rem' },
        },
        identificacion: {
            value: '',
            export: true,
            header: 'No. Identificación:',
            filterField: 'identificacion',
            filterPlaceholder: 'Buscar por número de identificación',
            table: true,
            filter: true,
            sort: true,
            matchMode: FilterMatchMode.CONTAINS,
            headerStyle: { width: '20%', minWidth: '15rem' }
        },
        estado: {
            value: '',
            export: true,
            header: 'Estado:',
            filter: true,
            filterSelect: 1,
            table: true,
            lab: 'nombre',
            matchMode: FilterMatchMode.EQUALS,
            headerStyle: { width: '10%', minWidth: '10rem' }
        },
        pendiente: {
            value: '',
            export: true,
            header: 'Anexos Pendientes de áreas: requirentes u Oficial de cumplimiento:',
            table: true,
            filter: true,
            filterSelect: 0,
            lab: 'nombre',
            matchMode: 'isNull',
            taq: 'same',
            severity: 'warning',
            headerStyle: { width: '10%', minWidth: '10rem' }
        },
    }
    optionLabel = 'nombre';
    mTitle = 'Instrucciones';
    mensaje = <><b>Instrucciones para completar el proceso de envío de información:</b><ol style={{ textAlign: 'justify' }}>
        <li>En cuanto se registre la información de las secciones de la 1 a la 8, favor guardar la información.</li>
        <li>Cuando la información se guarde, dar click en reporte y el sistema generará el formulario de su información
            en versión PDF, el cual se deberá firmar electrónicamente, o imprimir y firmar físicamente. Si el formulario
            es firmado físicamente, este documento deberá ser entregado al vendedor.</li>
        <li>En el botón anexos se deberá cargar el formulario firmado y los anexos requeridos.</li>
        <li>Cuando el formulario y sus anexos sean cargados al sistema favor dar click en enviar.</li></ol></>;
    titleBVG = <span style={{ textAlign: 'justify', fontStyle: 'italic', fontSize: '10px' }}>Con el fin de obtener un conocimiento adecuado de las personas que desean iniciar o
        mantienen relaciones de negocios con BOLSA DE VALORES DE GUAYAQUIL S.A. BVG y que se encuentran en la categoría de Accionistas (Personas Naturales) y Directores, de acuerdo
        a la Ley vigente, Reglamento a la Ley, Resoluciones, Políticas y procedimientos internos, necesitamos que Usted nos proporcione los siguientes datos. Es importante que los
        campos no queden en blanco, es posible que alguna información no aplique, en cuyo caso deberá expresarse escribiendo N/A.</span>;
    mensajeBVG = <><b>Instrucciones para completar el proceso de envío de información:</b><ol style={{ textAlign: 'justify' }}>
        <li>Mientras registre sus datos en el sistema, los mismos se guardarán cada vez que oprima el botón “Siguiente”.</li>
        <li>En cuanto registre la información de las secciones de la 1 a la 5, por favor debe guardarla. Si declara que es un PEP, debe generar adicionalmente este formulario que
            aparecerá en la última sección y cargarlo antes de enviar el formulario principal.</li>
        <li>Cuando la información se guarde, dar click en “Reporte” y el sistema generará el formulario principal en versión PDF, el cual deberá firmar electrónicamente, o imprimir y firmar físicamente.</li>
        <li>En el botón “Anexos” deberá cargar el formulario firmado y los documentos requeridos, por lo que necesitará lo siguiente:
            <ul>
                <li>Cédula de Identidad/Ciudadanía o Pasaporte legibles del Declarante.</li>
                <li>Cédula de Identidad/Ciudadanía o Pasaporte legibles del Cónyuge e Hijos.</li>
                <li>Impuesto a la renta del año inmediato anterior o constancia de la información pública del SRI.</li>
                <li>Certificado Bancario con promedio de cifras, o estados de cuenta mínimo con movimientos a la fecha.</li>
                <li>Registro Único de Contribuyentes, si aplica.</li>
                <li>Declaración Patrimonial Juramentada vigente o del último año fiscal (opcional reemplaza al formulario.</li>
                <li>Declaración de Origen y Destino Lícito de Recursos, si aplica.</li>
                <li>Recibo de cualquiera de los servicios básicos, no mayor a un mes de la dirección declarada como domicilio.</li>
            </ul>
        </li>
        <li>Cuando el formulario y sus anexos sean cargados al sistema favor dar click en “Enviar”.</li></ol></>;
}
