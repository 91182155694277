import { AccionistaPn } from "../../models/AccionistaPn";
import { GenericComponent } from "../../components/GenericComponent";
import { useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import { AccionistaService } from "../../service/AccionistaService";
import { InputComponent } from "../../components/InputComponent";
import { Toast } from "primereact/toast";
import { Card } from "primereact/card";
import { Toolbar } from "primereact/toolbar";
import { addLocale } from "primereact/api";
import { Panel } from "primereact/panel";
import { CatalogoService } from "../../service/CatalogoService";
import { FileComponent } from '../../components/FileComponent';
import { Divider } from 'primereact/divider';
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useToken } from "../../components/useToken";
import { FiltroMultiempresa } from "../../components/FiltroMultiempresa";

export const Accionista = () => {

    const generic = new GenericComponent();
    const omodel = new AccionistaPn();
    const model = omodel.model;
    const [permss, setPermss] = useState(null);
    const defaultValues = generic.default(model);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues, trigger, setValue } = useForm({ defaultValues });
    const [accionistas, setAccionistas] = useState([]);
    const accionistaServ = new AccionistaService();
    const catalogoSrv = new CatalogoService();
    const toast = useRef(null);
    const [dialogAccionista, setDialogAccionista] = useState(false);
    const [catalogos, setCatalogos] = useState(null);
    const [ecuador, setEcuador] = useState(false);
    const [ciudades, setCiudades] = useState([]);
    const [ecuadorDP, setEcuadorDP] = useState(false);
    const [ciudadesDP, setCiudadesDP] = useState([]);
    const [selectedCiiu, setSelectedCiiu] = useState(null);
    const [ciius, setCiius] = useState([]);
    const [loadReport, setLoadReport] = useState(false);
    const [esPep, setEsPep] = useState(false);
    const [esVinculoPep, setEsVinculoPep] = useState(false);
    const [tieneConyugue, setTieneConyugue] = useState(false);
    const [esCedula, setEsCedula] = useState(false);
    const [esCedulaConyugue, setEsCedulaConyugue] = useState(false);
    const [ecuadorNac, setEcuadorNac] = useState(false);
    const [selectedOtroCiiu, setSelectedOtroCiiu] = useState(null);
    const [otroCiius, setOtroCiius] = useState([]);
    const [conyugueCiius, setConyugueCiius] = useState([]);
    const [empresa, setEmpresa] = useState(null);
    const { getRol, getPerss } = useToken();
    const [selectedConyugueCiiu, setSelectedConyugueCiiu] = useState(null);
    const { table, setLoading, setTotalRecords, lazyParams, inDrop, inText, inCalendar, inNumber, inArea, inAutocomplete } =
        InputComponent({ registers: accionistas, omodel, generic, errors, control, permss, id: getValues('id') });
    const { upLoadFile, nuevoUpload, eliminarConfirmacionAnexo, anexoPendiente, enviarFormulario, listaFormulario, listar, dialogInstruccion, setdialogIns } =
        FileComponent({ generic, tipo: 'accionistaPn', toast, unuevo: 'ficha/accionista/store', udelete: 'ficha/accionista/eliminar-anexo', permss, id: getValues('id') });
    const [siCliente, setSiCliente] = useState(false);

    addLocale(generic.idioma, generic.esFormat);

    useEffect(() => {
        setSiCliente(getPerss() != undefined);
        if (getPerss() == undefined) {
            loadLazyData();
        }
        else {
            setPermss(getPerss());
            editFicha({ id: 0 });
        }
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);
        let _lazyParams = lazyParams;
        delete _lazyParams.rtipo;
        delete _lazyParams.empresa;
        if (permss == null) {
            _lazyParams.rtipo = true;
        }
        if (empresa) {
            _lazyParams.empresa = empresa;
        }
        if (getRol()) {
            _lazyParams.rol = getRol();
        }
        accionistaServ.getAccionista({ lazyEvent: JSON.stringify(lazyParams) }).then(res => {
            setTotalRecords(res.data.total);
            setAccionistas(res.data.registros);
            setLoading(false);
            if (res.data.perss !== undefined) {
                setPermss(res.data.perss);
            }
        });
    }

    const openNew = () => {
        if (catalogos == null) {
            accionistaServ.newAccionista({ iniciar: 1 }).then(res => {
                setCatalogos(res.data);
                setdialogIns(true);
                setDialogAccionista(true);
                reset(defaultValues);
            });
        }
        else {
            setdialogIns(true);
            setDialogAccionista(true);
            reset(defaultValues);
        }
    }

    const onSubmit = (data) => {
        data.fecha = generic.dateToString(data.fecha);
        data.fecha_nacimiento = generic.dateToString(data.fecha_nacimiento);
        data.ecn = catalogos?.ecuador_nac;
        data.casado = catalogos?.casado;
        data.union = catalogos?.union;
        if (tieneConyugue) {
            data.fecha_nacimiento_ec = generic.dateToString(data.fecha_nacimiento_ec);
        }
        else {
            delete data.fecha_nacimiento_ec;
        }
        if (esPep || esVinculoPep) {
            data.fecha_nombramiento_pep = generic.dateToString(data.fecha_nombramiento_pep);
            if (data.fecha_dejo_pep) {
                data.fecha_dejo_pep = generic.dateToString(data.fecha_dejo_pep)
            }
        }
        else {
            delete data.fecha_nombramiento_pep;
            delete data.fecha_dejo_pep;
        }
        if (ecuadorNac) {
            delete data.tiempo_reside_extranjero;
        }

        data.ec = catalogos.ecuador.cat_id;
        data.ecDP = catalogos.ecuador.cat_id;

        if (getValues('id') == null) {
            accionistaServ.newAccionista(data).then(res => {
                setValue('id', res.data);
                setdialogIns(true);
                trigger('id');
                generic.funcSuccess(res, toast);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
        else {
            accionistaServ.editarAccionista(data).then(res => {
                setdialogIns(true);
                generic.funcSuccess(res, toast);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    const onChangeTipo = (e, pers) => {
        if (pers) {
            setEsCedula(JSON.stringify(e.value) === JSON.stringify(catalogos.cedula));
        }
        else {
            setEsCedulaConyugue(JSON.stringify(e.value) === JSON.stringify(catalogos.cedula));
        }
    }

    const onChangePais = (e, pago) => {
        setEcuador(JSON.stringify(e.value) === JSON.stringify(catalogos.ecuador));
    }

    const onChangeProvincia = (e, pago) => {
        catalogoSrv.getCatalogosId(e.value.cat_id).then(res => {
            setCiudades(res.data);
        });
    }

    const onChangePaisDP = (e, pago) => {
        setEcuadorDP(JSON.stringify(e.value) === JSON.stringify(catalogos.ecuador));
    }

    const onChangeProvinciaDP = (e, pago) => {
        catalogoSrv.getCatalogosId(e.value.cat_id).then(res => {
            setCiudadesDP(res.data);
        });
    }

    const archivos = (data) => {
        const urls = "ficha/accionista/listar";
        const urlsd = "ficha/accionista/descarga";
        listar(data.id, urls, urlsd, data.nemonico);
    }

    const editFicha = (accionista) => {
        const iniciar = catalogos == null ? 1 : null;
        accionistaServ.consultarAccionista(accionista.id, iniciar).then(res => {
            let cats = catalogos;
            if (iniciar == 1) {
                cats = res.catalogos;
                setCatalogos(res.catalogos);
            }
            if (!res.accionista) {
                reset(defaultValues);
                setDialogAccionista(true);
                return;
            }
            let _accionista = res.accionista;
            setTieneConyugue(_accionista.estado_civil == cats.casado.cat_id || _accionista.estado_civil == cats.union.cat_id);
            let lista = {
                tipo_juridico: cats.tipoPersonaAccionista,
                tipo_identificacion_pn: cats.tipo_identificacion,
                pais: cats.pais,
                genero: cats.genero,
                nacionalidad: cats.nacionalidad,
                pais_dp: cats.pais,
                tipo_formulario: cats.tipoFormulario,
                estado_civil: cats.estado_civil,
                pep: generic.estadosSiNo,
                esta_relacionado_pep: generic.estadosSiNo,
                grupo_empresarial: generic.estadosSiNo,
                acepta_declaracion: generic.estadosSiNo,
            };
            //Conyugue
            if (_accionista.estado_civil == cats.casado.cat_id || _accionista.estado_civil == cats.union.cat_id) {
                lista['tipo_identificacion_ec'] = cats.tipo_identificacion;
                lista['nacionalidad_ec'] = cats.nacionalidad;
                _accionista.fecha_nacimiento_ec = generic.stringToDate(_accionista.fecha_nacimiento_ec);
            }
            if (res.ciudades != undefined) {
                lista['ciudad'] = res.ciudades;
                lista['provincia'] = cats.provincias;
                setCiudades(res.ciudades);
            }
            if (res.ciudades_dp != undefined) {
                lista['ciudad_dp'] = res.ciudades_dp;
                lista['provincia_dp'] = cats.provincias;
                setCiudadesDP(res.ciudades_dp);
            }
            setEsPep(_accionista.pep);
            setEsVinculoPep(_accionista.esta_relacionado_pep);
            if (_accionista.pep || _accionista.esta_relacionado_pep) {
                if (_accionista.fecha_dejo_pep) {
                    _accionista.fecha_dejo_pep = generic.stringToDate(_accionista.fecha_dejo_pep);
                }
                _accionista.fecha_nombramiento_pep = generic.stringToDate(_accionista.fecha_nombramiento_pep);
            }
            Object.keys(lista).forEach(function (key) {
                if (_accionista[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _accionista[key]) {
                            _accionista[key] = element;
                        }
                    });
                }
            });
            setEcuador(JSON.stringify(_accionista.pais) === JSON.stringify(cats.ecuador));
            setEcuadorDP(JSON.stringify(_accionista.pais_dp) === JSON.stringify(cats.ecuador));
            setEcuadorNac(JSON.stringify(_accionista.nacionalidad) === JSON.stringify(cats.ecuador_nac));

            //Para actualizaciones
            _accionista.fecha = generic.stringToDate(_accionista.fecha);
            _accionista.fecha_nacimiento = generic.stringToDate(_accionista.fecha_nacimiento);

            if (_accionista['actividad_economica'] != null) {
                _accionista['actividad_economica'] = res.actividad_economica;
                setSelectedCiiu(res.actividad_economica);
            }
            if (_accionista['otra_actividad_economica'] != null) {
                _accionista['otra_actividad_economica'] = res.otra_actividad_economica;
                setSelectedOtroCiiu(res.otra_actividad_economica);
            }
            if (_accionista['otra_actividad_economica_cony'] != null) {
                _accionista['otra_actividad_economica_cony'] = res.otra_actividad_economica_cony;
                setSelectedConyugueCiiu(res.otra_actividad_economica_conyugue);
            }
            setdialogIns(true);

            reset(generic.renewValues(_accionista, model));
            setDialogAccionista(true);
        });
    };

    const searchCiiu = (event, tipo) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                if (tipo === 'ciiu') {
                    setCiius([...ciius]);
                } else if (tipo === 'otro') {
                    setOtroCiius([...otroCiius]);
                } else if (tipo === 'conyugue') {
                    setConyugueCiius([...conyugueCiius]);
                }
            }
            else {
                catalogoSrv.getActividades(event.query.toLowerCase()).then(data => {
                    if (tipo === 'ciiu') {
                        setCiius(data.data);
                    }
                    else if (tipo === 'otro') {
                        setOtroCiius(data.data);
                    } else if (tipo === 'conyugue') {
                        setConyugueCiius(data.data);
                    }
                });
            }
        });
    }

    const confirmDelete = (data) => {
        confirmDialog({
            message: '¿Está seguro de eliminar el formulario?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteAccionista(data)
        });
    }

    const deleteAccionista = (data) => {
        accionistaServ.eliminarAccionista(data.id).then(res => {
            loadLazyData();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const onChangePep = (e, es) => {
        if (es) {
            setEsPep(e.value.cat_id);
        }
        else {
            setEsVinculoPep(e.value.cat_id);
        }
    }

    const generarReporte = () => {
        setLoadReport(true);
        accionistaServ.descargar(getValues('id')).then(res => {
            generic.downloadPdf(res, getValues('id') + '-Formulario-Accionista-PN.pdf');
            setLoadReport(false);
        }).catch(error => {
            setLoadReport(false);
            generic.funcMessageBlobError('Complete la información antes de generar.', error, toast);
        });
    }

    const onChangeNacionalidad = (e) => {
        setEcuadorNac(JSON.stringify(e.value) === JSON.stringify(catalogos.ecuador_nac));
    }

    const onChangeEstadoCivil = (e) => {
        setTieneConyugue(JSON.stringify(e.value) === JSON.stringify(catalogos.casado) || JSON.stringify(e.value) === JSON.stringify(catalogos.union));
    }

    const subirReporte = () => {
        const urls = "ficha/accionista/listar";
        const urlsd = "ficha/accionista/descarga";
        nuevoUpload(getValues('id'), 'Subir formulario y adjuntos', urls, urlsd);
    }

    const enviar = () => {
        enviarFormulario(getValues('id'), 'ficha/accionista/enviar', 'empleado');
    }

    const actualizarPatrimonio = (e, info) => {
        const actvs = ['ACTV_INMUEBLES', 'ACTV_MUEBLES', 'ACTV_EFECTIVO', 'ACTV_INVERSIONES', 'ACTV_CREDITOS', 'ACTV_OTROS'];
        const pasvs = ['PASV_BANCARIOS', 'PASV_HIPOTECARIOS', 'PASV_QUIROGRAFARIO', 'PASV_CREDITO', 'PASV_OTRAS_CTAS', 'PASV_OTROS_PASV'];
        let totalPasivos = 0;
        let totalActivos = 0;
        actvs.forEach(itm => {
            if (getValues(itm) && !isNaN(getValues(itm))) {
                totalActivos += parseFloat(getValues(itm));
            }
        });
        pasvs.forEach(itm => {
            if (getValues(itm) && !isNaN(getValues(itm))) {
                totalPasivos += parseFloat(getValues(itm));
            }
        });
        setValue('total_activos', totalActivos);
        setValue('total_pasivos', totalPasivos);
        setValue('total_patrimonio', totalActivos - totalPasivos);
    }

    return (
        <>
            <ConfirmDialog />
            <Toast ref={toast} />
            {!dialogAccionista && !siCliente && <>
                {getRol() && getRol()?.ideg && <FiltroMultiempresa loadLazyData={loadLazyData} setEmpresa={setEmpresa} />}
                <Toolbar className="mb-4" start={(getRol() && getRol()?.ideg) ? null : generic.leftToolbarTemplate(openNew, 'Nuevo', permss)}
                    end={generic.rightToolbarTemplate(accionistas, omodel, 'Listado_accionista_pn', 'LISTADO ACCIONISTA PERSONA NATURAL')}></Toolbar>
                <Card style={{ maxWidth: '100%', margin: 'auto' }}>
                    {table({ action3: generic.actionEditDetailTemplate, methEdit: editFicha, perm: permss, methDelete: confirmDelete, details: archivos, icono: 'pi pi-clone' })}
                </Card></>
            }
            {dialogAccionista &&
                <Panel header={(getValues('id') == null ? 'NUEVO' : 'EDITAR') + " FORMULARIO DE ACCIONISTA PERSONA NATURAL"} style={{ width: '100%', margin: 'auto' }}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="formgrid grid">
                            {inDrop('tipo_juridico', catalogos?.tipoPersonaAccionista, { lab: omodel.optionLabel })}

                            {inCalendar('fecha')}
                            {inDrop('pais', catalogos?.pais, { lab: omodel.optionLabel, chn: onChangePais, val: false })}
                            {ecuador && inDrop('provincia', catalogos?.provincias, { lab: omodel.optionLabel, chn: onChangeProvincia, val: false })}
                            {ecuador && inDrop('ciudad', ciudades, { lab: omodel.optionLabel })}
                            {!ecuador && inText('canton')}

                            {inText('compania')}
                            {inDrop('grupo_empresarial', generic.estadosSiNo, { lab: omodel.optionLabel })}
                            {inDrop('tipo_formulario', catalogos?.tipoFormulario, { lab: omodel.optionLabel })}
                        </div>
                        <Panel header="1) DATOS GENERALES" className="panel-ficha">
                            <div className="formgrid grid">
                                {inText('apellidos_dp')}
                                {inText('nombres_dp')}
                                {inDrop('tipo_identificacion_pn', catalogos?.tipo_identificacion, { lab: omodel.optionLabel, chn: onChangeTipo, val: true })}
                                {!esCedula && inText('identificacion')}
                                {esCedula && inText('identificacion', generic.infoIdentificacion('cedula'))}
                            </div>
                            <div className="formgrid grid">
                                {inDrop('nacionalidad', catalogos?.nacionalidad, { lab: omodel.optionLabel, chn: onChangeNacionalidad })}
                                {inDrop('genero', catalogos?.genero, { lab: omodel.optionLabel })}
                                {inCalendar('fecha_nacimiento')}
                            </div>
                            <div className="formgrid grid">
                                {inArea('direccion', 5, 300)}
                                {inDrop('pais_dp', catalogos?.pais, { lab: omodel.optionLabel, chn: onChangePaisDP, val: false })}
                                {ecuadorDP && inDrop('provincia_dp', catalogos?.provincias, { lab: omodel.optionLabel, chn: onChangeProvinciaDP, val: false })}
                                {ecuadorDP && inDrop('ciudad_dp', ciudadesDP, { lab: omodel.optionLabel })}
                                {!ecuadorDP && inText('canton_dp')}
                                {!ecuadorNac && inText('tiempo_reside_extranjero')}

                                {inText('email', { pattern: omodel.pattern })}
                                {inText('telefono_celular')}
                                {inText('telefono_domicilio')}
                                {inDrop('estado_civil', catalogos?.estado_civil, { lab: omodel.optionLabel, chn: onChangeEstadoCivil })}
                            </div>
                        </Panel>
                        <br />

                        <Panel header="2) DATOS DEL CÓNYUGE / CONVIVIENTE (Si aplica):" className="panel-ficha">
                            {tieneConyugue && <>
                                <div className="formgrid grid">
                                    {inText('apellidos_ec')}
                                    {inText('nombres_ec')}
                                    {inDrop('tipo_identificacion_ec', catalogos?.tipo_identificacion, { lab: omodel.optionLabel, chn: onChangeTipo, val: false })}
                                    {!esCedulaConyugue && inText('identificacion_ec')}
                                    {esCedulaConyugue && inText('identificacion_ec', generic.infoIdentificacion('cedula'))}

                                    {inDrop('nacionalidad_ec', catalogos?.nacionalidad, { lab: omodel.optionLabel })}
                                    {inCalendar('fecha_nacimiento_ec')}

                                    {inText('lugar_trabajo_ec')}
                                    {inText('cargo_ec')}
                                    {inAutocomplete('actividad_economica', selectedCiiu, setSelectedCiiu, ciius, searchCiiu, { lab: omodel.optionLabel, val: 'ciiu' })}
                                </div>
                            </>}
                        </Panel>
                        <br />

                        <Panel header="3) INFORMACIÓN ECONÓMICA" className="panel-ficha">
                            <div className="formgrid grid">
                                {inNumber('ingreso_mensual_rel_dep', 2, true)}
                                {inNumber('otros_ingresos', 2, true)}
                                {inText('fuente_ingresos')}
                                {inAutocomplete('otra_actividad_economica', selectedOtroCiiu, setSelectedOtroCiiu, otroCiius, searchCiiu, { lab: omodel.optionLabel, val: 'otro' })}

                                {tieneConyugue && inNumber('in_mensual_rel_dep_cony', 2, true)}
                                {tieneConyugue && inNumber('otros_ingresos_cony', 2, true)}

                                {tieneConyugue && inText('fuente_ingresos_cony')}
                                {tieneConyugue && inAutocomplete('otra_actividad_economica_cony', selectedConyugueCiiu, setSelectedConyugueCiiu, conyugueCiius, searchCiiu, { lab: omodel.optionLabel, val: 'conyugue' })}

                                {inNumber('gasto_mensual', 2, true)}
                            </div>
                        </Panel>
                        <br />

                        <Panel header="4) DECLARACIÓN PATRIMONIAL" className="panel-ficha">
                            <div className="formgrid grid">
                                {inNumber('ACTV_INMUEBLES', 2, true, { func: actualizarPatrimonio, vars: null })}
                                {inNumber('PASV_BANCARIOS', 2, true, { func: actualizarPatrimonio, vars: null })}
                            </div>

                            <div className="formgrid grid">
                                {inNumber('ACTV_MUEBLES', 2, true, { func: actualizarPatrimonio, vars: null })}
                                {inNumber('PASV_HIPOTECARIOS', 2, true, { func: actualizarPatrimonio, vars: null })}
                            </div>

                            <div className="formgrid grid">
                                {inNumber('ACTV_EFECTIVO', 2, true, { func: actualizarPatrimonio, vars: null })}
                                {inNumber('PASV_QUIROGRAFARIO', 2, true, { func: actualizarPatrimonio, vars: null })}
                            </div>

                            <div className="formgrid grid">
                                {inNumber('ACTV_INVERSIONES', 2, true, { func: actualizarPatrimonio, vars: null })}
                                {inNumber('PASV_CREDITO', 2, true, { func: actualizarPatrimonio, vars: null })}
                            </div>

                            <div className="formgrid grid">
                                {inNumber('ACTV_CREDITOS', 2, true, { func: actualizarPatrimonio, vars: null })}
                                {inNumber('PASV_OTRAS_CTAS', 2, true, { func: actualizarPatrimonio, vars: null })}
                            </div>

                            <div className="formgrid grid">
                                {inNumber('ACTV_OTROS', 2, true, { func: actualizarPatrimonio, vars: null })}
                                {inNumber('PASV_OTROS_PASV', 2, true, { func: actualizarPatrimonio, vars: null })}
                            </div>
                            <Divider />
                            <div className="formgrid grid">
                                {inNumber('total_activos', 2, true, { disabled: true })}
                                {inNumber('total_pasivos', 2, true, { disabled: true })}
                            </div>
                            <div className="formgrid grid">
                                {inNumber('total_patrimonio', 2, true, { disabled: true, min: 'nulo' })}
                            </div>
                        </Panel>
                        <br />

                        <Panel header="5) PERSONA EXPUESTA POLÍTICAMENTE (PEP) DESCRIBIR EN EL CASO QUE SOCIOS, ACCIONISTAS O DIRECTIVOS OCUPEN ESTOS CARGOS" className="panel-ficha">
                            <div className="field col" style={{ textAlign: 'justify', fontStyle: 'italic' }}>
                                {omodel.strPep}
                            </div>
                            <Divider />
                            <div className="formgrid grid">
                                {inDrop('pep', generic.estadosSiNo, { lab: omodel.optionLabel, chn: onChangePep, val: true })}
                                {inDrop('esta_relacionado_pep', generic.estadosSiNo, { lab: omodel.optionLabel, chn: onChangePep, val: false })}

                                {(esPep || esVinculoPep) && inText('relacion')}
                                {(esPep || esVinculoPep) && inText('nombre_pep')}

                                {(esPep || esVinculoPep) && inText('cargo_pep')}
                                {(esPep || esVinculoPep) && inText('institucion_trabaja_pep')}

                                {(esPep || esVinculoPep) && inCalendar('fecha_nombramiento_pep')}
                                {(esPep || esVinculoPep) && inCalendar('fecha_dejo_pep')}
                            </div>
                        </Panel>
                        <br />

                        <Panel header="6) DECLARACIÓN" className="panel-ficha">
                            <div className="field col" style={{ textAlign: 'justify' }}>
                                {omodel.strDeclara}
                                <br /><br />
                                <Divider />
                                {inDrop('acepta_declaracion', generic.estadosSiNo, { lab: omodel.optionLabel })}
                            </div>
                        </Panel>
                        <br />

                        <Panel header="7) ANEXOS" className="panel-ficha">
                            <div className="formgrid grid">
                                {omodel.strAnexo}
                            </div>
                        </Panel>
                        <br />

                        <Panel header="8) OBSERVACIONES" className="panel-ficha">
                            <div className="formgrid grid">
                                {inArea('observacion', 5, 9000, 12)}
                            </div>
                        </Panel>
                        <br />
                        {generic.buttonsFormsReports(setDialogAccionista, generarReporte, subirReporte, enviar, loadReport, getValues('id') != null, !siCliente)}
                    </form>
                </Panel>
            }
            {upLoadFile()}
            {eliminarConfirmacionAnexo()}
            {anexoPendiente('Enviar formulario - pendientes', 'Los siguientes anexos están pendientes de subir')}
            {listaFormulario('ficha/accionista/aprobar', loadLazyData)}
            {dialogInstruccion(omodel.titulo, omodel.mensaje)}
        </>
    )
}
