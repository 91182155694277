import { useEffect, useState } from "react";
import { GenericComponent } from "../../../components/GenericComponent";
import { Vinculo } from "../../../models/fun/Vinculo";
import { FuncionarioService } from "../../../service/ficha/FuncionarioService";
import { useForm } from "react-hook-form";
import { InputComponent } from "../../../components/InputComponent";
import { Generico } from "../../../components/Generico";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { CatalogoService } from "../../../service/CatalogoService";
import { VinculacionFamilia } from "./VinculacionFamilia";
import { Message } from "primereact/message";
import { Button } from "primereact/button";
import { VinculacionRelacion } from "./VinculacionRelacion";
import { Divider } from "primereact/divider";
import { FileComponent } from "../../../components/FileComponent";
import { Dialog } from "primereact/dialog";
import { FileBvg } from "../../../components/FileBvg";

export const VinculacionBvg = ({ tipo, id, permss, toast, reiniciar, catalogos, urls, idp, btnRegresar }) => {
    const generic = new GenericComponent();
    const omodel = new Vinculo();
    const funSrv = new FuncionarioService();
    const { funcError, funDefault, funcSuccess, stringToDate, buttonsBGV, downloadPdf, funcMessageBlobError } = Generico();
    const defaultValues = funDefault(omodel.model);
    const [loadReport, setLoadReport] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [dialogSubir, setDialogSubir] = useState(false);
    const [vinc, setVinc] = useState(null);
    const [ciudades, setCiudades] = useState(null);
    const [siguiente, setSiguiente] = useState(null);
    const catalogoSrv = new CatalogoService();
    const [ecuador, setEcuador] = useState(false);
    const { enviarFormularioPep } = FileComponent({ generic, toast });
    const { control, formState: { errors }, handleSubmit, reset, setError } = useForm({ defaultValues });
    const { inDrop, inText, inCalendar } = InputComponent({ omodel, errors, control, permss, id: vinc?.id });

    useEffect(() => {
        editFicha();
    }, [id]);
    const editFicha = () => {
        funSrv.consultar(id ?? vinc?.id ?? 0, 'vinculacion', idp ?? 0).then(res => {
            const _vin = res.data.vin;
            const _cats = catalogos
            const lista = {
                pais: _cats.pais,
                tipo_declaracion: _cats.tipoFormulario,
                tipo_identificacion: _cats.tipo
            };
            if (res.data.ciudades !== undefined) {
                lista.ciudad = res.data.ciudades;
                lista.provincia = _cats.provincias;
                setCiudades(res.data.ciudades);
            }
            Object.keys(lista).forEach(function (key) {
                if (_vin && _vin[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _vin[key]) {
                            _vin[key] = element;
                        }
                    });
                }
            });
            if (_vin?.fecha) _vin.fecha = stringToDate(_vin.fecha);
            if (_vin?.pais) setEcuador(JSON.stringify(_vin.pais) === JSON.stringify(_cats.ecuador));
            setVinc(_vin);
            reset(generic.renewValues(_vin ?? defaultValues, omodel.model));
        });
    }

    const onSubmit = (data) => {
        data.tipo = tipo;
        data.idp = idp;
        data.indice = activeIndex;
        if (activeIndex === 0) {
            data.fecha = data.fecha.toISOString().split('T')[0];
        }
        data.ec = catalogos.ecuador;
        if (vinc?.id == null) {
            funSrv.nuevo(data, 'vinculacion').then(res => {
                setVinc(res.data);
                funcSuccess(res, toast);
                actionBtn();
            }).catch(error => {
                funcError(error, toast, setError);
            });
        }
        else {
            funSrv.editar(data, 'vinculacion').then(res => {
                funcSuccess(res, toast);
                actionBtn();
            }).catch(error => {
                funcError(error, toast, setError);
            });
        }
    }

    const actionBtn = () => {
        if (siguiente && activeIndex <= 1) {
            setActiveIndex(activeIndex + 1);
        }
        if (!siguiente && activeIndex > 0) {
            setActiveIndex(activeIndex - 1);
        }
    }

    const onError = (errors) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se detectaron errores, por favor revise el formulario para corregir.', life: 3000 });
    }

    const onChangePais = (e) => {
        setEcuador(JSON.stringify(e.value) === JSON.stringify(catalogos.ecuador));
    }

    const onChangeProvincia = (e) => {
        catalogoSrv.getCatalogosId(e.value.cat_id).then(res => {
            setCiudades(res.data);
        });
    }

    const btnNext = (sig, ssubmit = 'submit') => {
        return (
            <>
                <Button label={sig ? "Siguiente" : "Anterior"} type={ssubmit} icon={sig ? "pi pi-chevron-right" : "pi pi-chevron-left"} className="p-button-success"
                    onClick={() => { setSiguiente(sig); if (ssubmit !== 'submit') { setActiveIndex(sig ? activeIndex + 1 : activeIndex - 1) } }} style={{ width: '130px' }} />
            </>
        )
    }

    const generarReporte = () => {
        setLoadReport(true);
        funSrv.descargar(id ?? vinc?.id, 'vinculacion').then(res => {
            downloadPdf(res, (id ?? vinc?.id) + '-Formulario-vinculacion.pdf');
            setLoadReport(false);
        }).catch(error => {
            setLoadReport(false);
            funcMessageBlobError('Complete la información antes de generar.', error, toast);
        });
    }

    const subirReporte = () => {
        setDialogSubir(true);
    }

    const enviar = () => {
        enviarFormularioPep(vinc?.id ?? id, urls.uenviar, tipo, reiniciar);
    }

    return (
        <>
            <Panel header="FORMATO DE VINCULACION CON TERCEROS: PARTICIPES DEL MERCADO DE VALORES Y OTROS" className="panel-ficha">
                <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                    <TabPanel header="Sección 1">
                        {activeIndex === 0 &&
                            <form onSubmit={handleSubmit(onSubmit, onError)}>
                                <div className="formgrid grid">
                                    {inDrop('tipo_declaracion', catalogos?.tipoFormulario, { lab: omodel.optionLabel })}
                                    {inCalendar('fecha')}
                                    {inDrop('pais', catalogos?.pais, { lab: omodel.optionLabel, chn: onChangePais })}
                                    {ecuador && inDrop('provincia', catalogos?.provincias, { lab: omodel.optionLabel, chn: onChangeProvincia })}
                                    {ecuador && inDrop('ciudad', ciudades, { lab: omodel.optionLabel })}
                                    {!ecuador && inText('canton')}

                                    {inText('nombre_completo', { readOnly: tipo !== 'todos' })}
                                    {inDrop('tipo_identificacion', catalogos?.tipo, { lab: omodel.optionLabel, ne: tipo !== 'todos' })}
                                    {inText('identificacion', { readOnly: tipo !== 'todos' })}
                                    <div className="field col-12">
                                        {(vinc?.idp && parseInt(vinc?.idp) > 0) && <><Message severity="info" style={{ width: '100%' }} text={omodel.msgVin} /><br /></>}
                                        {btnNext(true)}
                                    </div>
                                </div>
                            </form>
                        }
                    </TabPanel>
                    <TabPanel header="Sección 2" disabled={!vinc?.id}>
                        {activeIndex === 1 && <>
                            <VinculacionFamilia id={vinc?.id} toast={toast} permss={permss} catalogos={catalogos} opcion="vinculacion-funcionario" />
                            <br />

                            <VinculacionRelacion id={vinc?.id} toast={toast} permss={permss} catalogos={catalogos} opcion="vinculacion-relacion" />

                            <div className="field col-12"><br />{btnNext(false, 'button')}&nbsp;{btnNext(true, 'button')}</div>
                        </>}
                    </TabPanel>
                    <TabPanel header="Sección 3" disabled={!vinc?.id}>
                        {activeIndex === 2 && <div className="formgrid grid">
                            <Panel header="DECLARACIÓN Y AUTORIZACIÓN" className="panel-ficha col-12">
                                <div className="field col-12" style={{ textAlign: 'justify' }}>
                                    {omodel.cap}
                                </div>

                                <div className="field col-2" style={{ textAlign: 'center' }}>
                                    <Divider />
                                    <span style={{ fontWeight: 'bold', fontSize: '10px' }}>Firma del Representante Legal</span>
                                </div>
                                <div className="field col-3"></div>

                                <Divider />
                                <div className="field col-12" style={{ textAlign: 'justify' }}>
                                    {omodel.cap1}
                                </div>
                            </Panel>

                            <div className="field col-1"><br />{btnNext(false, 'button')}</div>
                            <div className="field col-11" style={{ textAlign: 'center' }}>
                                <br />
                                {buttonsBGV(null, null, generarReporte, subirReporte, enviar, loadReport, vinc?.id || id)}
                            </div>
                        </div>}
                    </TabPanel>
                </TabView>
                {btnRegresar !== undefined && btnRegresar()}
            </Panel>

            <Dialog visible={dialogSubir} header="Gestión de anexos" modal className="p-fluid modal-ssize" onHide={e => setDialogSubir(false)} style={{ width: '800px' }}>
                <FileBvg generic={generic} tipo={tipo} urls={urls} idp={vinc?.id} toast={toast} permss={permss} estado={vinc?.estado} reiniciar={reiniciar} setDialogSubir={setDialogSubir} />
            </Dialog>
        </>
    )
}
