import { useForm } from "react-hook-form";
import { Notificado } from "../../../models/cpn/Notificado"
import { NotificaService } from "../../../service/personaNatural/NotificaService";
import { useEffect, useState } from "react";
import { InputComponent } from "../../../components/InputComponent";
import { confirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import { Generico } from "../../../components/Generico";

export const NotificadoBVG = ({ idp, generic, toast, permss }) => {

    const omodel = new Notificado();
    const defaultValues = generic.default(omodel.model);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues });
    let loadLazyTimeout = null;
    const { accionesTbl } = Generico();
    const notificaSrv = new NotificaService();
    const [registros, setRegistros] = useState(null);
    const [dialogNotifica, setDialogNotifica] = useState(false);
    const [nota, setNota] = useState(null);
    const [load, setLoad] = useState(false);
    const { table, setLoading, setTotalRecords, lazyParams, inText } = InputComponent({ registers: registros, omodel, generic, errors, control, permss, id: getValues('id') });

    useEffect(() => {
        loadLazy();
    }, [idp]);

    const loadLazy = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }

        let _lazyParams = lazyParams;
        _lazyParams.idp = idp;
        loadLazyTimeout = setTimeout(() => {
            notificaSrv.getLista({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setRegistros(res.data.registros);
                setLoading(false);
            });
        });
    }

    const onSubmit = (data) => {
        data.idp = idp;
        setLoad(true);
        if (getValues('id') == null) {
            notificaSrv.nuevo(data).then(res => {
                setLoad(false);
                setDialogNotifica(false);
                loadLazy();
                generic.funcSuccess(res, toast);
            }).catch(error => {
                setLoad(false);
                generic.funcError(error, toast, setError);
            });
        }
        else {
            notificaSrv.editar(data).then(res => {
                setLoad(false);
                setDialogNotifica(false);
                loadLazy();
                generic.funcSuccess(res, toast);
            }).catch(error => {
                setLoad(false);
                generic.funcError(error, toast, setError);
            });
        }
    }

    const openNew = () => {
        setNota(null);
        reset(defaultValues);
        setDialogNotifica(true);
    }

    const confirmDelete = () => {
        confirmDialog({
            message: '¿Está seguro de eliminar la información?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteNotifica()
        });
    };

    const deleteNotifica = () => {
        notificaSrv.delete(nota?.id, idp).then(res => {
            loadLazy();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const editNotifica = () => {
        notificaSrv.consultar(nota?.id, idp).then(res => {
            reset(res.data);
            setDialogNotifica(true);
        });
    }

    const headerTemplate = (options) => {
        return (
            <div className={options.className}>
                <div className="flex align-items-center gap-2">
                    <Button icon="pi pi-plus" className="p-button-rounded p-button-success mr-2" type="button" onClick={openNew} title="Registrar información"
                        style={{ display: permss?.crear ? 'block' : 'none' }} />
                    <span className="font-bold">Notificaciones</span>
                </div>
            </div>
        );
    }

    const itemsMenu = () => {
        const items = [];
        if (permss?.actualizar)
            items.push({ label: 'Editar', icon: 'pi pi-pencil', command: () => { editNotifica() } });
        if (permss?.eliminar)
            items.push({ label: 'Eliminar', icon: 'pi pi-trash', command: () => { confirmDelete() } });

        return [{ label: 'Opciones', items: items }];
    }

    return (
        <>
            <Panel headerTemplate={headerTemplate}>
                {table({ action01: accionesTbl, reg: setNota, items: itemsMenu() })}
            </Panel>
            <Dialog visible={dialogNotifica} header={getValues('id') == null ? "Nueva información" : "Editar información"} modal className="p-fluid modal-ssize"
                onHide={e => setDialogNotifica(false)} style={{ width: '400px' }}>
                <br />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="formgrid grid">
                        {inText('ausencia_contacto', {}, 12)}
                        {inText('nombre_firma', {}, 12)}
                        {inText('cargo', {}, 12)}
                        {inText('correo_electronico', {}, 12)}
                    </div>

                    {generic.buttonsForms(setDialogNotifica, load)}
                </form>
            </Dialog>
        </>
    )
}
